import React from "react";
import i18next from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "hr",
    debug: false,
    detection: {
      order: ["localStorage", "htmlTag", "cookie", "path", "subdomain"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const i18n = () => {
  return <div></div>;
};

export default i18n;
