import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import React, { Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "./contexts/AuthContext.jsx";
import "./components/i18n/i18n";

const Login = React.lazy(() => import("./components/Admin/Login/Login"));
const AddNewRealEstate = React.lazy(() =>
  import("./components/Admin/Admin/Upload/AddNewRealEstate")
);

const AboutUs = React.lazy(() =>
  import("./components/AboutUs/AboutUsPage/AboutUsPage")
);

const ForgotPassword = React.lazy(() =>
  import("./components/Admin/Login/ForgotPassword")
);

const RealEstateList = React.lazy(() =>
  import("./components/Admin/Admin/RealEstateList")
);

const RealEstateDetail = React.lazy(() =>
  import("./components/RealEstateDetails/RealEstateDetail")
);

const EditRealEstate = React.lazy(() =>
  import("./components/Admin/Admin/Upload/EditRealEstate")
);

const FrontPage = React.lazy(() => import("./components/FrontPage"));

const App = () => {
  const { currentUser } = useAuth();

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div
            style={{ height: "100vh" }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
            <div>
              <span>...Loading</span>
            </div>
          </div>
        }
      >
        <Routes>
          <Route exact path="/" element={<FrontPage />} />
          <Route exact path="/property/:id" element={<RealEstateDetail />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/about-us" element={<AboutUs />} />

          <Route
            exact
            path="/admin"
            element={
              currentUser ? <RealEstateList /> : <Navigate to="/login" />
            }
          />

          <Route
            exact
            path="/admin/add-new-real-estate"
            element={
              currentUser ? <AddNewRealEstate /> : <Navigate to="/login" />
            }
          />

          <Route
            exact
            path="/admin/real-estate-list"
            element={
              currentUser ? <RealEstateList /> : <Navigate to="/login" />
            }
          />

          <Route
            exact
            path="/admin/edit-real-estate/:id"
            element={
              currentUser ? <EditRealEstate /> : <Navigate to="/login" />
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
